import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../components/auth/RequireAuth';
import { RequireTenant } from '../components/auth/RequireTenant';
import ActionsMonitorView from './service-provider/actions/ActionsMonitorView';
import * as React from 'react';
import TenantConnectionsView from './tenant/TenantConnectionsView';

function TenantAppView() {
  return (
    <Routes>
      <Route
        path="/connections/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <TenantConnectionsView />
            </RequireTenant>
          </RequireAuth>
        }
      />
      <Route
        path="/monitor/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <ActionsMonitorView />
            </RequireTenant>
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default TenantAppView;
