import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  JSX,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import {
  ExportedTenantIntegrations,
  IntegrationControlAction,
  IntegrationDefinition,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationNodeType } from '@apus/common-lib/integration-engine/src/interface';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { TenantContext } from '@apus/common-ui/state/tenantContext';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Forward5TwoToneIcon from '@mui/icons-material/Forward5TwoTone';
import PauseTwoToneIcon from '@mui/icons-material/PauseTwoTone';
import PlayArrowTwoToneIcon from '@mui/icons-material/PlayArrowTwoTone';
import StopTwoToneIcon from '@mui/icons-material/StopTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Box, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid';

import { AppContext } from '../../state/appContext';
import { setIntegrations } from '../../state/appReducer';
import { systemglueAppNames } from '../subscriptions/apps/names';
import CreateAppVersionDialog from './CreateAppVersionDialog';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import useTenant from '@apus/common-ui/hooks/useTenant';
import { format } from 'date-fns';
import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import { IntegrationService } from '@apus/common-ui/api-client/integration-service';

interface RowIntegration {
  id: string;
  app: SystemglueAppName;
  appName: string;
  integration: IntegrationDefinition;
  integrationName: string;
  url: string;
}

function asRowIntegration(
  integration: IntegrationDefinition,
  tenantId: string
): RowIntegration {
  const app = integration.app ?? SystemglueAppName.BASE_INTEGRATION_APP;
  return {
    id: integration.integrationId,
    app,
    appName: systemglueAppNames[app],
    integrationName: integration.name,
    integration: integration,
    url: `${process.env.REACT_APP_REST_API_ENDPOINT}/integrations/${tenantId}/triggers/${integration.integrationId}`,
  };
}
const RenderControlsCell = (props: GridCellParams<RowIntegration>) => {
  const apiContext = useGridApiContext();

  const [tenantState] = useContext(TenantContext);
  const [_, appDispatch] = useContext(AppContext);

  const { hasFocus, row } = props;
  const integrationService = useIntegrationService();
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const rippleRef = useRef<TouchRippleActions | null>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      rippleRef.current.stop({} as any);
    }
  }, [hasFocus]);

  const onAction = async (
    event: React.MouseEvent,
    action: IntegrationControlAction
  ) => {
    event.stopPropagation();

    await executeApiCall<IntegrationDefinition>({
      callFunction: () =>
        integrationService
          .executeControlAction(row.id, action)
          .then(async integration => {
            try {
              apiContext.current.updateRows([
                asRowIntegration(
                  integration,
                  tenantState.tenant?.tenantId ?? '-'
                ),
              ]);
            } catch (e) {
              console.error(`apiContext.current.updateRows failed`);
              console.error(e);
            }
            appDispatch(
              setIntegrations(await integrationService.listIntegrations())
            );
            return integration;
          }),
    });
  };

  return (
    <Box>
      <IconButton
        aria-label="Run integration"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        disabled={!row.integration?.allowedActions?.includes('Run')}
        onClick={event => onAction(event, 'Run')}
      >
        <Forward5TwoToneIcon />
      </IconButton>
      <IconButton
        aria-label="Start integration"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        disabled={
          row.integration.isReadOnly ||
          !row.integration?.allowedActions?.includes('Start')
        }
        onClick={event => onAction(event, 'Start')}
      >
        <PlayArrowTwoToneIcon />
      </IconButton>
      <IconButton
        aria-label="Pause integration"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        disabled={
          row.integration.isReadOnly ||
          !row.integration?.allowedActions?.includes('Pause')
        }
        onClick={event => onAction(event, 'Pause')}
      >
        <PauseTwoToneIcon />
      </IconButton>
      <IconButton
        aria-label="Stop integration"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        disabled={
          row.integration.isReadOnly ||
          !row.integration?.allowedActions?.includes('Stop')
        }
        onClick={event => onAction(event, 'Stop')}
      >
        <StopTwoToneIcon />
      </IconButton>
    </Box>
  );
};

const RenderActionsCell = (props: GridCellParams<RowIntegration>) => {
  const { hasFocus, row } = props;
  const navigate = useNavigate();
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const rippleRef = useRef<TouchRippleActions | null>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      rippleRef.current.stop({} as any);
    }
  }, [hasFocus]);

  const onEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`${row.id}`);
  };

  const onCopy = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await navigator.clipboard.writeText(JSON.stringify(row.integration));
  };

  return (
    <Box>
      <IconButton
        aria-label="Copy integration to clipboard"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        style={{ marginLeft: 16 }}
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        onClick={onCopy}
      >
        <ContentCopyTwoToneIcon color={'success'} />
      </IconButton>
      <IconButton
        aria-label="Open integration"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        style={{ marginLeft: 16 }}
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        onClick={onEdit}
      >
        <EditTwoToneIcon />
      </IconButton>
    </Box>
  );
};

const RenderUrlCell = (props: GridCellParams<RowIntegration>) => {
  const { hasFocus, value } = props;
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const rippleRef = useRef<TouchRippleActions | null>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      rippleRef.current.stop({} as any);
    }
  }, [hasFocus]);

  const onCopyContent = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (value !== undefined) {
      await navigator.clipboard.writeText(String(value));
    }
  };

  if (
    props.row.integration.workflow.trigger.nodeType ===
      IntegrationNodeType.WebhookTrigger &&
    props.row.integration.workflow.trigger.internal !== true
  )
    return (
      <Button
        aria-label="Copy url to clipboard"
        ref={buttonElement}
        touchRippleRef={rippleRef}
        size="small"
        style={{ marginLeft: 16 }}
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ') {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
        onClick={onCopyContent}
        startIcon={<ContentCopyIcon />}
      >
        Copy URL
      </Button>
    );

  return '';
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Actions',
    sortable: false,
    renderCell: RenderActionsCell,
    width: 180,
  },
  {
    field: 'integration',
    headerName: 'Control',
    sortable: false,
    renderCell: RenderControlsCell,
    width: 180,
  },
  {
    field: 'url',
    headerName: 'Trigger URL',
    width: 150,
    renderCell: RenderUrlCell,
  },
  {
    field: 'appName',
    headerName: 'App',
    sortable: true,
    width: 180,
  },
  { field: 'integrationName', headerName: 'Integration', width: 600 },
];

function getSelectedIntegrationIds(
  apiRef: React.MutableRefObject<GridApi>
): string[] {
  // Select rows and columns
  const selectedIntegrationIds = gridRowSelectionStateSelector(
    apiRef.current.state
  );
  if (selectedIntegrationIds.length === 0) return [];

  return selectedIntegrationIds.map(id => String(id));
}

const exportBlob = (blob: Blob, filename: string) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

const exportIntegrations = async (
  apiRef: React.MutableRefObject<GridApiCommunity>,
  tenant: Tenant | undefined,
  integrationService: IntegrationService
) => {
  const integrations: ExportedTenantIntegrations =
    await integrationService.exportIntegrations({
      app: SystemglueAppName.BASE_INTEGRATION_APP,
      integrationIds: getSelectedIntegrationIds(apiRef),
    });

  const json = JSON.stringify(integrations, null, 2);

  if (json !== undefined) {
    const blob = new Blob([json], {
      type: 'text/json',
    });
    exportBlob(
      blob,
      `${tenant?.tenantName.trim().replace(/\s/g, '-').toLowerCase()}-${
        tenant?.tenantId
      }-integrations-${format(new Date(), 'yyyy-MM-dd')}.json`
    );
  }
};

const IntegrationList = (): JSX.Element => {
  const [appState, dispatch] = useContext(AppContext);
  const [tenantContext] = useContext(TenantContext);
  const [showCreateAppVersionDialog, setShowCreateAppVersionDialog] =
    useState<boolean>(false);
  const [selectedIntegrationIds, setSelectedIntegrationIds] = useState<
    string[]
  >([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const gridApiRef = useGridApiRef();
  const tenant = useTenant();
  const integrationService = useIntegrationService();

  const listIntegrations = useCallback(
    () => integrationService.listIntegrations(),
    [integrationService]
  );

  useEffect(() => {
    (async () => {
      if (appState.lastUpdated.integrations === undefined) {
        await executeApiCall<IntegrationDefinition[]>({
          callFunction: listIntegrations,
          setResult: integrations => {
            dispatch(setIntegrations(integrations));
          },
        });
      }
    })();
  }, [appState.lastUpdated.integrations, dispatch, listIntegrations]);

  function createAppVersion() {
    setSelectedIntegrationIds(getSelectedIntegrationIds(gridApiRef));
    setShowCreateAppVersionDialog(true);
  }

  return appState.integrations === undefined ? (
    <></>
  ) : (
    <Grid
      container
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CreateAppVersionDialog
        open={showCreateAppVersionDialog}
        handleClose={() => setShowCreateAppVersionDialog(false)}
        integrationIds={selectedIntegrationIds}
      />
      <Grid item xs={1}>
        <Box flexGrow={1} flexDirection={'row'} display={'flex'}>
          <FormControlLabel
            control={<Switch onChange={(_, checked) => setEditMode(checked)} />}
            label="Edit"
          />
        </Box>
      </Grid>
      <Grid item xs={11}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <Button
            aria-label="App version"
            size="small"
            style={{ marginLeft: 16 }}
            disabled={!editMode}
            onClick={createAppVersion}
            startIcon={<IntegrationInstructionsTwoToneIcon color={'success'} />}
          >
            App version
          </Button>
          <Button
            aria-label="Export integrations"
            size="small"
            style={{ marginLeft: 16 }}
            disabled={!editMode}
            onClick={() =>
              exportIntegrations(gridApiRef, tenant, integrationService)
            }
            startIcon={<FileDownloadTwoToneIcon color={'info'} />}
          >
            Export
          </Button>
          <Button
            aria-label="Delete"
            size="small"
            style={{ marginLeft: 16 }}
            disabled={!editMode}
            //onClick={onCopyContent}
            startIcon={<DeleteTwoToneIcon color={'error'} />}
          >
            Delete
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}></Grid>
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          apiRef={gridApiRef}
          checkboxSelection={editMode}
          disableRowSelectionOnClick
          columns={columns}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '100%',
          }}
          rows={appState.integrations.map(integration =>
            asRowIntegration(integration, tenantContext.tenant?.tenantId ?? '')
          )}
          initialState={{
            sorting: {
              sortModel: [{ field: 'integrationName', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default IntegrationList;
