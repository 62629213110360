import React, { JSX, useCallback, useEffect, useState } from 'react';

import { ApiError } from '@apus/common-lib/api/interface/common';
import {
  AppSubscription,
  SystemglueApp,
} from '@apus/common-lib/api/interface/subscriptions';
import useSubscriptionService from '@apus/common-ui/hooks/useSubscriptionService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Box, Button, Grid, Stack } from '@mui/material';

import { createEmptyBankTransactionIntegrationApp } from './apps/ConfigureBankTransactionIntegrationApp';
import { createEmptyPaymentIntegrationApp } from './apps/ConfigurePaymentIntegrationApp';
import { createEmptySystemglueIntegrationApp } from './apps/ConfigureSystemglueIntegrationApp';
import ConfigureExistingSubscription from './ConfigureExistingSubscription';
import ConfigureNewSubscription from './ConfigureNewSubscription';
import ShowApiCallStatus from '../alert/ShowApiCallStatus';

interface Props {
  tenantId: string;
}

const ConfigureSubscriptions = ({ tenantId }: Props): JSX.Element => {
  const subscriptionService = useSubscriptionService();

  const [subscriptions, setSubscriptions] = useState<AppSubscription[]>([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] =
    useState<boolean>(false);
  const [errorLoadingSubscriptions, setErrorLoadingSubscriptions] = useState<
    ApiError | undefined
  >();

  const [newApp, setNewApp] = useState<SystemglueApp>();

  const resetNewApp = useCallback(() => {
    // reset new subscription
    setNewApp(undefined);
  }, [setNewApp]);

  function updateSubscription(subscription: AppSubscription) {
    setSubscriptions(
      [...subscriptions].map(s => {
        if (s.id === subscription.id) return subscription;
        return s;
      })
    );
  }

  const refreshSubscriptions = useCallback(async () => {
    await executeApiCall<AppSubscription[]>({
      callFunction: () =>
        subscriptionService.listSubscriptions(tenantId).then(result => {
          resetNewApp();
          return result;
        }),
      setResult: setSubscriptions,
      setPending: setIsLoadingSubscriptions,
      setError: setErrorLoadingSubscriptions,
    });
  }, [resetNewApp, tenantId, subscriptionService]);

  useEffect(() => {
    if (tenantId !== undefined) {
      (async () => {
        await refreshSubscriptions();
      })();
    }
  }, [tenantId, refreshSubscriptions]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} spacing={2}>
        <ShowApiCallStatus
          infoText={'loading subscriptions'}
          loading={isLoadingSubscriptions}
          error={errorLoadingSubscriptions}
        />
        {newApp !== undefined ? (
          <ConfigureNewSubscription
            app={newApp}
            onCancelled={resetNewApp}
            onCreated={refreshSubscriptions}
          />
        ) : (
          <Box margin={2}>
            <Stack spacing={2} direction={'row'}>
              <Button
                onClick={() => setNewApp(createEmptySystemglueIntegrationApp())}
              >
                Add Systemglue -subscription
              </Button>
              <Button
                onClick={() => setNewApp(createEmptyPaymentIntegrationApp())}
              >
                Add payment integration -subscription
              </Button>
              <Button
                onClick={() =>
                  setNewApp(createEmptyBankTransactionIntegrationApp())
                }
              >
                Add bank transaction integration -subscription
              </Button>
            </Stack>
          </Box>
        )}
        {subscriptions.map(subscription => (
          <ConfigureExistingSubscription
            key={subscription.id}
            subscription={subscription}
            onChange={updateSubscription}
            onDelete={refreshSubscriptions}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ConfigureSubscriptions;
