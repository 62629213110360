import { AxiosError } from 'axios';
import { ApiError } from '@apus/common-lib/api/interface/common';
import { ApiCommandResult } from '@apus/common-lib/api/interface/integration-service';

interface CallOptions<RESULT_TYPE> {
  callFunction: () => Promise<RESULT_TYPE>;
  setPending?: (pending: boolean) => void;
  setResult?: (result: RESULT_TYPE) => void;
  setError?: (error: ApiError | undefined) => void;
}

interface PollingOptions<RESULT_TYPE> {
  /**
   * Polling interval in milliseconds
   */
  interval: number;
  /**
   * Timeout in milliseconds
   */
  timeout?: number;
  /**
   * Test for ending polling
   *
   * @param result
   */
  endCondition?: (result: RESULT_TYPE) => boolean;
  onFinish?: () => void;
}

interface ApiCommandOptions<RESULT_TYPE extends ApiCommandResult>
  extends Pick<
    CallOptions<RESULT_TYPE>,
    'setResult' | 'setError' | 'setPending'
  > {
  commandFunction: () => Promise<RESULT_TYPE>;
  resultFunction: (resultId: string) => Promise<RESULT_TYPE>;
  polling?: Omit<PollingOptions<RESULT_TYPE>, 'endCondition'>;
}

function makeErrorHandler<RESULT_TYPE>({
  setPending,
  setError,
}: Pick<CallOptions<RESULT_TYPE>, 'setPending' | 'setError'>) {
  return function handleError(error: any) {
    if (error instanceof AxiosError && error.response !== undefined) {
      const apiError = error.response?.data as ApiError;

      if (setError !== undefined)
        setError({
          ...apiError,
          ...(apiError.statusCode === undefined && {
            statusCode: error.status ?? 500,
          }),
          ...(apiError.message === undefined && {
            message: error.message,
          }),
        });
    } else {
      if (setError !== undefined)
        setError({
          message: error.message,
          statusCode:
            typeof error['status'] === 'number'
              ? error['status']
              : parseInt(error.status ?? '500'),
        });
    }

    if (setPending !== undefined) setPending(false);
  };
}

export async function executeApiCall<RESULT_TYPE>({
  callFunction,
  setPending,
  setResult,
  setError,
}: CallOptions<RESULT_TYPE>) {
  const handleError = makeErrorHandler<RESULT_TYPE>({ setPending, setError });

  if (setPending !== undefined) setPending(true);

  await callFunction
    .apply(undefined)
    .then(result => {
      if (setResult !== undefined) setResult(result);
      if (setPending !== undefined) setPending(false);
    })
    .catch(handleError);
}

export async function executePollingApiCall<RESULT_TYPE>({
  callFunction,
  setPending,
  setResult,
  setError,
  polling,
}: CallOptions<RESULT_TYPE> & { polling: PollingOptions<RESULT_TYPE> }) {
  let timer: NodeJS.Timeout | undefined = undefined;

  const handleError = makeErrorHandler<RESULT_TYPE>({ setPending, setError });

  function isEndConditionReached(result: RESULT_TYPE): boolean {
    return polling.endCondition !== undefined && polling.endCondition(result);
  }

  const call = async (): Promise<void> => {
    try {
      const result = await callFunction.apply(undefined);

      if (setResult !== undefined) setResult(result);

      if (isEndConditionReached(result)) {
        clearInterval(timer);
        if (setPending !== undefined) setPending(false);
        if (polling.onFinish !== undefined) polling.onFinish();
      }
    } catch (e) {
      console.log(`Polling failed - clearing interval`);
      clearInterval(timer);
      handleError(e);
      if (polling.onFinish !== undefined) polling.onFinish();
    }
  };

  if (setPending !== undefined) setPending(true);

  try {
    timer = setInterval(() => call(), polling.interval);
    if (polling.timeout !== undefined && timer !== undefined)
      setTimeout(() => {
        clearInterval(timer);
        if (setPending !== undefined) setPending(false);
        if (polling.onFinish !== undefined) polling.onFinish();
      }, polling.timeout);
  } catch (e) {
    clearInterval(timer);
    handleError(e);
    if (polling.onFinish !== undefined) polling.onFinish();
  }
}

/**
 * Execute an api command exposed by a tenant
 *
 * @param commandFunction the function which calls the exposed api command
 * @param resultFunction the function which will be used to poll the result for the command
 * @param setPending callback to set 'loading' status
 * @param setResult callback to set the result once it is acquired
 * @param setError callback to set an error result
 * @param polling the polling options to use
 */
export async function executeApiCommand<RESULT_TYPE extends ApiCommandResult>({
  commandFunction,
  resultFunction,
  setPending,
  setResult,
  setError,
  polling = {
    interval: 1000,
    timeout: 30000,
  },
}: ApiCommandOptions<RESULT_TYPE>) {
  let timer: NodeJS.Timeout | undefined = undefined;

  // create an error handler
  const handleError = makeErrorHandler<RESULT_TYPE>({ setPending, setError });

  // call the command function to start the workflow and get the result id
  const initialResult = await commandFunction.apply(undefined).catch(e => {
    handleError(e);
    if (polling.onFinish !== undefined) polling.onFinish();
  });

  if (initialResult === undefined) return;

  function stopPolling() {
    if (timer !== undefined) clearInterval(timer);
    timer = undefined;
  }

  const call = async (): Promise<void> => {
    // call the result function to check if a result has been reached
    const result = await resultFunction.apply(undefined, [
      initialResult.resultId,
    ]);

    if (setResult !== undefined) {
      setResult(result);
    }

    if (result.resultStatus !== 'pending') {
      stopPolling();
      if (setPending !== undefined) setPending(false);
      if (polling.onFinish !== undefined) polling.onFinish();
    }
  };

  if (setPending !== undefined) setPending(true);

  try {
    timer = setInterval(() => call(), polling.interval);

    if (polling.timeout !== undefined && timer !== undefined)
      setTimeout(() => {
        // if timer is still defined, then the polling has failed
        if (timer !== undefined) {
          if (setPending !== undefined) setPending(false);
          if (polling.onFinish !== undefined) polling.onFinish();
          if (setError !== undefined) {
            setError({
              message: 'Command result was not received in time',
              statusCode: 500,
            });
          }
          stopPolling();
        }
      }, polling.timeout);
  } catch (e) {
    handleError(e);
    stopPolling();
    if (polling.onFinish !== undefined) polling.onFinish();
  }
}
