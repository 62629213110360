import React, { JSX, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { AppContext } from '../../../state/appContext';
import { bodyMaxHeight } from '../../../theme/theme';

interface ConnectionProps {
  name: string;
  description?: string;
  enabled: boolean;
  module: string;
}

const Connection = ({
  name,
  description,
  enabled,
  module,
}: ConnectionProps): JSX.Element => {
  const navigate = useNavigate();

  const onAdd = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`./${module}`);
  };

  return (
    <Box
      sx={{
        height: 100,
        width: 250,
        backgroundColor: 'lavender',
        border: 1,
        textAlign: 'center',
        borderRadius: 3,
        boxShadow: 3,
        padding: 1,
        margin: 1,
      }}
    >
      <Typography variant={'h6'}>{name}</Typography>
      {description !== undefined && (
        <Typography variant={'body1'}>{description}</Typography>
      )}
      {enabled ? (
        <Button
          variant={'contained'}
          aria-label="Save"
          component="label"
          size={'small'}
          onClick={onAdd}
          color={'success'}
        >
          Enabled
        </Button>
      ) : (
        <Button
          variant={'contained'}
          aria-label="Save"
          component="label"
          size={'small'}
          onClick={onAdd}
          color={'warning'}
        >
          Add
        </Button>
      )}
    </Box>
  );
};

const ConnectionListView = (): JSX.Element => {
  const [appState] = useContext(AppContext);

  function isEnabled(module: SupportedModule): boolean {
    return (
      appState.integrationConnections?.some(
        configuration =>
          configuration.moduleId === module && configuration.enabled
      ) ?? false
    );
  }

  return (
    <Box
      flexWrap={'wrap'}
      display={'flex'}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Connection
        name={'Systemglue'}
        module={'internal'}
        enabled={isEnabled('internal')}
      />
      <Connection
        name={'Oracle Netsuite'}
        module={'netsuite'}
        enabled={isEnabled('netsuite')}
      />
      <Connection
        name={'AWS Web Services'}
        module={'aws'}
        enabled={isEnabled('aws')}
      />
      <Connection
        name={'Delfoi Planner'}
        module={'delfoi'}
        enabled={isEnabled('delfoi')}
      />
      <Connection
        name={'VariPDM'}
        module={'vari-pdm'}
        enabled={isEnabled('vari-pdm')}
      />
      <Connection
        name={'Hubspot'}
        module={'hubspot'}
        enabled={isEnabled('hubspot')}
      />
      <Connection
        name={'MRPEasy'}
        module={'mrpeasy'}
        enabled={isEnabled('mrpeasy')}
      />
      <Connection
        name={'SkyPlanner'}
        module={'skyplanner'}
        enabled={isEnabled('skyplanner')}
      />
      <Connection
        name={'Cargoson'}
        module={'cargoson'}
        enabled={isEnabled('cargoson')}
      />
      <Connection
        name={'Osuuspankki'}
        module={'osuuspankki'}
        enabled={isEnabled('osuuspankki')}
      />
      <Connection
        name={'Airtable'}
        module={'airtable'}
        enabled={isEnabled('airtable')}
      />
      <Connection
        name={'Netvisor'}
        module={'netvisor'}
        enabled={isEnabled('netvisor')}
      />
      <Connection
        name={'Pagero'}
        module={'pagero'}
        enabled={isEnabled('pagero')}
      />
      <Connection
        name={'Nordea Business API'}
        module={'nordea-business'}
        enabled={isEnabled('nordea-business')}
      />
      <Connection
        name={'Microsoft Graph API'}
        module={'microsoft-graph'}
        enabled={isEnabled('microsoft-graph')}
      />
    </Box>
  );
};

export default ConnectionListView;
