import React, { JSX, useEffect, useState } from 'react';
import { Routes, Route, useSearchParams, Link } from 'react-router-dom';
import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import useOAuthService from '@apus/common-ui/hooks/useOAuthService';
import { executePollingApiCall } from '@apus/common-ui/utils/api-call';
import { OAuthAuthorizationCodeFlowResult } from '@apus/common-lib/api/interface/oauth-service';
import { isEqual } from 'lodash';

function AuthorizeConnectionView({
  connection,
}: {
  connection: SupportedModule;
}): JSX.Element {
  const oauthService = useOAuthService();
  const [searchParams] = useSearchParams();
  const [authResult, setAuthResult] =
    useState<OAuthAuthorizationCodeFlowResult>();
  const [authError, setAuthError] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      setAuthResult(undefined);
      setAuthError(undefined);
      const state = searchParams.get('state');
      const code = searchParams.get('code');
      const error = searchParams.get('error');

      if (state != null) {
        if (code != null) {
          await executePollingApiCall<OAuthAuthorizationCodeFlowResult>({
            callFunction: () =>
              oauthService.handleAuthorizationCode(connection, code, state),
            setError: err => {
              setAuthError(
                `Authorization code was received successfully, but it could not be handled: ${err?.message}`
              );
            },
            setResult: result => {
              setAuthResult(result);
            },
            polling: {
              endCondition: result => {
                if (result.status === 'ERROR') return true;
                return isEqual(result.status, 'FINISHED');
              },
              interval: 1000,
              timeout: 20000,
            },
          });
        }
        if (error != null) {
          setAuthError(`Authorization failed: ${error}`);
        }
      }
    })();
  }, [searchParams, oauthService, connection, setAuthResult, setAuthError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Connecting to {connection}</Typography>
        {isEqual(authResult?.status, 'FINISHED') && authError === undefined && (
          <CircularProgress />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box>
          {!isEqual(authResult?.status, 'FINISHED') && (
            <Alert severity={'success'}>
              Connection established!{' '}
              <Link to="../../connections">
                Click here to go back to connections
              </Link>
            </Alert>
          )}
        </Box>
        <Box>{authError && <Alert severity={'error'}>{authError}</Alert>}</Box>
      </Grid>
    </Grid>
  );
}

const IntegrationsView = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="authorize/microsoft-graph"
        element={<AuthorizeConnectionView connection={'microsoft-graph'} />}
      />
      <Route
        path="authorize/pagero"
        element={<AuthorizeConnectionView connection={'pagero'} />}
      />
      <Route
        path="authorize/nordea-business"
        element={<AuthorizeConnectionView connection={'nordea-business'} />}
      />
    </Routes>
  );
};

export default IntegrationsView;
