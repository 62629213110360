import { SourceObject } from '../../json-data-mapper/src/interface';
import { JSONSchema7 } from 'json-schema';
import {
  AccessTokenStorage,
  EnvironmentConfiguration,
  ModuleConfiguration,
  TenantIntegrationClient,
} from '../../api/interface/integration-service';
import {
  CustomContentMediaType,
  JsonSchemaDefinition,
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
} from '../../api/interface/files';
import { APIGatewayProxyEvent } from 'aws-lambda';
import { TenantSubscriptions } from '../../api/interface/tenant-service';
import { unknownAppSubscription } from '../../api/schemas/subscriptions';
import { OAuthAuthorizationCodeFlow } from '@apus/common-lib/api/interface/oauth-service';

export interface ConfigurationValueSchema {
  type: 'string' | 'integer' | 'boolean';
  enum?: string[];
  const?: string | number | boolean;
  title: string;
  description?: string;
  default?: string | number | boolean;
  contentMediaType?:
    | CustomContentMediaType.TypescriptFunction
    | CustomContentMediaType.LiquidTemplate
    | 'application/json';
  // validation
  maximum?: number;
  minimum?: number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  /**
   * When 'false', this configuration setting will be configurable only at the operation definition stage i.e. is
   * independent of the workflows in which this operation is used.
   *
   * When 'true', this configuration setting will be configurable only at the workflow stage and will also be subject to
   * workflow context i.e. will be provided with input and output schemas. In short, the value of this configuration
   * is dependent on the context (the workflow) in which it is used.
   */
  runtime?: boolean;
  /**
   * Is the configuration property a secret?
   *
   * When true, the value is omitted from responses in the API
   */
  secret?: boolean;
}

export interface ConfigurationSchema {
  $schema?: string;
  type: 'object';
  title: string;
  description?: string;
  required?: string[];
  properties: {
    [propName: string]: ConfigurationValueSchema;
  };
}

export type DataSchema = JSONSchema7;

export interface EventContext {
  actionId: string;
  integrationId: string;
  integrationName?: string;
  tenantId: string;
  tenantName?: string;
  correlationId?: string;
  invocationDateTime: string;
  previousInvocationDateTime?: string;
  tenantSubscriptions?: TenantSubscriptions;
}

export const eventContextSchema: JSONSchema7 = {
  type: 'object',
  required: ['tenantId', 'integrationId', 'actionId', 'invocationDateTime'],
  properties: {
    tenantId: {
      type: 'string',
      format: 'uuid',
      description: 'Tenant id',
    },
    tenantName: {
      type: 'string',
      description: 'Tenant name',
    },
    integrationId: {
      type: 'string',
      format: 'uuid',
      description: 'Integration id',
    },
    integrationName: {
      type: 'string',
      description: 'Integration name',
    },
    actionId: {
      type: 'string',
      description: 'Action id',
    },
    correlationId: {
      type: 'string',
      description:
        'A correlation id that can be supplied when action is created',
    },
    invocationDateTime: {
      type: 'string',
      format: 'date-time',
      description: 'Date and time of the current run time for the workflow',
    },
    previousInvocationDateTime: {
      type: 'string',
      format: 'date-time',
      description: 'Date and time of the last run time for the workflow',
    },
    tenantSubscriptions: {
      type: 'object',
      description: 'Subscriptions currently active in the tenant',
      required: ['tenant'],
      properties: {
        tenant: {
          type: 'object',
          required: ['tenantId', 'tenantName'],
          properties: {
            tenantId: {
              type: 'string',
            },
            tenantName: {
              type: 'string',
            },
            serviceProviderId: {
              type: 'string',
              format: 'uuid',
            },
            businessId: {
              type: 'string',
            },
          },
        },
        subscriptions: {
          type: 'array',
          items: unknownAppSubscription as JSONSchema7,
        },
      },
    },
  },
};

export interface RunContext {
  trigger: unknown | undefined;
  operations: {
    [id: string]: unknown;
  };
}

export const runContextSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    operations: {
      type: 'object',
      properties: {},
    },
  },
};

/**
 * Module identifier for the integration itself
 */
export type BaseModule = 'base';

/**
 * Modules not intended to be used except internally
 */
export const InternalModuleNames = ['mock'] as const;

/**
 * Supported modules
 *
 * NOTE: names can only consist of lower-case letters [a-z] and slashes [-]
 */
export const SupportedModuleNames = [
  'netsuite',
  'hubspot',
  'http',
  'aws',
  'slack',
  'debug',
  'banking',
  'internal',
  'delfoi',
  'vari-pdm',
  'osuuspankki',
  'airtable',
  'netvisor',
  'pagero',
  'microsoft-graph',
  'mrpeasy',
  'skyplanner',
  'nordea-business',
  'cargoson',
] as const;

const AllModules = [...InternalModuleNames, ...SupportedModuleNames];
// Optional modules are those that are always enabled, but can also have a separate configuration
export const optionalModules = ['internal', 'banking'];

export type SupportedModule = (typeof AllModules)[number];

export type Module = BaseModule | SupportedModule;

export type ModuleConfigurations = Partial<
  Record<SupportedModule, ModuleConfiguration>
>;

export interface ConnectionResolver {
  resolveTenantConfiguration: <CONFIGURATION_TYPE>(
    connection: SupportedModule
  ) => CONFIGURATION_TYPE | undefined;
  resolveProviderConfiguration: <CONFIGURATION_TYPE>(
    connection: SupportedModule
  ) => CONFIGURATION_TYPE | undefined;
}

export type OperationParameters = Pick<
  IntegrationOperation,
  'operationId' | 'inputSchema' | 'outputSchema'
> & {
  prototypeConfiguration?: SourceObject;
  tenantModuleConfiguration?: SourceObject;
  serviceProviderModuleConfiguration?: SourceObject;
  environmentConfiguration: EnvironmentConfiguration;
  eventContext: EventContext;
  tenantSubscriptions?: TenantSubscriptions;
  accessTokenStorage: AccessTokenStorage;
  tenantIntegrationClient: TenantIntegrationClient;
  connectionResolver: ConnectionResolver;
};

export interface OperationFuncArgs {
  parameters: OperationParameters;
  data: unknown;
}

export type OperationFunc = ({
  parameters,
  data,
}: OperationFuncArgs) => Promise<unknown | undefined>;

export type RequestAuthorizerFunc = ({
  moduleConfiguration,
  event,
}: {
  moduleConfiguration?: ModuleConfiguration;
  event: APIGatewayProxyEvent;
}) => Promise<boolean>;

export interface IntegrationDispatcherArgs {
  integrationId: string;
  data: unknown;
  createdFromActionId?: string;
  correlationId?: string;
  synchronous?: boolean;
}

export type ApiResultStatus = 'pending' | 'finished' | 'error';

export interface ApiResult<T = unknown> {
  resultStatus: ApiResultStatus;
  result?: T;
  error?: {
    httpCode?: number;
    message: string;
    details?: Record<string, unknown>;
  };
}

export interface ApiResultHandlerArgs {
  eventContext: EventContext;
  result: ApiResult;
}

export type ApiResultHandler = (args: ApiResultHandlerArgs) => void;

export type IntegrationDispatcher = ({
  integrationId,
  data,
  createdFromActionId,
  correlationId,
}: IntegrationDispatcherArgs) => Promise<void>;

export type HandlerResolverArgs = Pick<IntegrationOperation, 'prototype'> & {
  nodeId: string;
};

export type HandlerResolver = ({
  prototype,
  nodeId,
}: HandlerResolverArgs) => OperationFunc | undefined;

export interface OperationResolverArgs {
  moduleId: string;
  operationId: string;
}

export type OperationResolver = ({
  moduleId,
  operationId,
}: OperationResolverArgs) => IntegrationOperation | undefined;

export interface OperationHandlers {
  [operationPrototypeId: string]: OperationFunc;
}

export type RequestAuthorizerResolver = ({
  moduleId,
  authorizerId,
}: {
  moduleId: string;
  authorizerId: string;
}) => RequestAuthorizerFunc | undefined;

export interface RequestAuthorizers {
  [authorizerId: string]: RequestAuthorizerFunc;
}

export interface TenantModuleConfigurationCheckerArgs {
  moduleConfiguration?: ModuleConfiguration;
  serviceProviderModuleConfiguration?: ModuleConfiguration;
  subscriptions?: TenantSubscriptions;
}

export type TenantModuleConfigurationChecker = (
  args: TenantModuleConfigurationCheckerArgs
) => Promise<boolean>;

export type TenantModuleConfigurationCheckerResolver = ({
  moduleId,
}: {
  moduleId: string;
}) => TenantModuleConfigurationChecker | undefined;

export interface ServiceProviderModuleConfigurationCheckerArgs {
  moduleConfiguration?: ModuleConfiguration;
}

export type ServiceProviderModuleConfigurationChecker = (
  args: TenantModuleConfigurationCheckerArgs
) => Promise<boolean>;

export type ServiceProviderModuleConfigurationCheckerResolver = ({
  moduleId,
}: {
  moduleId: string;
}) => ServiceProviderModuleConfigurationChecker | undefined;

export interface OAuthAuthorizationCodeFlowHandler {
  getAuthorizationUrl: (args: {
    redirectUri: string;
    flow: OAuthAuthorizationCodeFlow;
    tenantModuleConfiguration?: SourceObject;
    serviceProviderModuleConfiguration?: SourceObject;
  }) => Promise<string>;
  handleAuthorizationCode: (args: {
    code: string;
    flow: OAuthAuthorizationCodeFlow;
    tenantModuleConfiguration?: SourceObject;
    serviceProviderModuleConfiguration?: SourceObject;
    accessTokenStorage: AccessTokenStorage;
  }) => Promise<SourceObject | undefined>;
}

export type OAuthAuthorizationCodeFlowHandlerResolver = ({
  moduleId,
}: {
  moduleId: string;
}) => OAuthAuthorizationCodeFlowHandler | undefined;

export interface OperationBase {
  moduleId: SupportedModule;
  operationId: string;
  title: string;
  description: string;
  /**
   * If true, no mapper is provided when configuring operation
   */
  noMapping?: boolean;
  inputSchema?: SupportedSchemaDefinition;
  outputSchema?: SupportedSchemaDefinition;
}

/**
 * Prototype operation defines an operation that has an implemented handler
 */
export interface IntegrationOperationPrototype
  extends Pick<
    OperationBase,
    | 'moduleId'
    | 'operationId'
    | 'title'
    | 'description'
    | 'inputSchema'
    | 'outputSchema'
    | 'noMapping'
  > {
  configurationSchema?: ConfigurationSchema;
}

/**
 * Integration operation defines a supported integration i.e. it defines a configured prototype and has
 * input and output schemas defined.
 */
export interface IntegrationOperation
  extends Pick<
    OperationBase,
    | 'moduleId'
    | 'operationId'
    | 'title'
    | 'description'
    | 'inputSchema'
    | 'outputSchema'
  > {
  /**
   * The operation prototype this builds on
   */
  prototype: IntegrationOperationPrototype;
  /**
   * Configuration based on prototype.configurationSchema
   */
  configuration?: SourceObject;
}

export enum WorkflowOperationType {
  INSTANCE = 'instance',
  EMBEDDED = 'embedded',
}

/**
 * Integration operation instance is a configured instance for given integration operation.
 *
 * Workflows use instances of operations i.e. define runtime configuration in addition to the other operation specifics.
 */
export interface IntegrationOperationInstance {
  readonly operationType: WorkflowOperationType.INSTANCE;
  readonly moduleId: string;
  readonly operationId: string;
  inputSchema?: JsonSchemaDefinition;
  mappingSchema?: SupportedMappingSchemaDefinition;
  outputSchema?: JsonSchemaDefinition;
  runtimeConfiguration?: SourceObject;
}

/**
 * Embedded integration operation is an operation embedded within a workflow
 */
export interface EmbeddedIntegrationOperation {
  readonly operationType: WorkflowOperationType.EMBEDDED;
  readonly moduleId: string;
  readonly operationId: string;
  inputSchema?: JsonSchemaDefinition;
  mappingSchema?: SupportedMappingSchemaDefinition;
  outputSchema?: JsonSchemaDefinition;
  title: string;
  description?: string;
  /**
   * The operation prototype this builds on
   */
  prototype: IntegrationOperationPrototype;
  /**
  /**
   * Configuration based on prototype.configurationSchema
   */
  configuration?: SourceObject;
  /**
   /**
   * Configuration based on prototype.runtimeConfigurationSchema
   */
  runtimeConfiguration?: SourceObject;
}

export type WorkflowOperation =
  | IntegrationOperationInstance
  | EmbeddedIntegrationOperation;

export interface RequestAuthorizer {
  moduleId: SupportedModule;
  authorizerId: string;
  name: string;
  description?: string;
}

export interface IntegrationModuleBase {
  moduleId: SupportedModule;
  title: string;
  description: string;
  configurationSchema?: ConfigurationSchema;
  providerConfigurationSchema?: ConfigurationSchema;
  requestAuthorizers?: {
    [authorizerId: string]: RequestAuthorizer;
  };
  operationPrototypes: {
    [operationId: string]: IntegrationOperationPrototype;
  };
}

export interface IntegrationModule extends IntegrationModuleBase {
  operations: {
    [operationId: string]: IntegrationOperation;
  };
}

export interface CustomIntegrationOperation {
  base: IntegrationOperation;
  id: string;
  operationId: string;
  title: string;
  description?: string;
  addedInputSchema?: DataSchema;
  addedOutputSchema?: DataSchema;
}
