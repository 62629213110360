import React, { JSX, useEffect, useState } from 'react';

import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import useOAuthService from '@apus/common-ui/hooks/useOAuthService';
import useTenant from '@apus/common-ui/hooks/useTenant';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';

function connectionDetails(moduleId: SupportedModule): {
  name: string;
  description?: string;
} {
  switch (moduleId) {
    case 'pagero':
      return {
        name: 'Pagero',
      };
    case 'microsoft-graph':
      return { name: 'Microsoft Graph API' };
    case 'nordea-business':
      return {
        name: 'Nordea Business API',
      };
  }

  return {
    name: moduleId,
  };
}

const Connection = ({
  moduleConfiguration,
}: {
  moduleConfiguration: ModuleConfiguration;
}): JSX.Element => {
  const oauthService = useOAuthService();
  const tenant = useTenant();

  const onAuthorize = async (event: React.MouseEvent) => {
    event.stopPropagation();

    const redirectUri = await oauthService.beginAuthorizationCodeFlow(
      moduleConfiguration.moduleId,
      tenant?.tenantId ?? ''
    );

    window.location.href = redirectUri;
  };

  const { name, description } = connectionDetails(moduleConfiguration.moduleId);

  return (
    <Grid item margin={2}>
      <Box
        sx={{
          minHeight: 100,
          width: 400,
          backgroundColor: 'lavender',
          border: 1,
          textAlign: 'center',
          borderRadius: 3,
          boxShadow: 3,
          padding: 1,
        }}
      >
        <Typography variant={'h6'} margin={1}>
          {name}
        </Typography>
        {description !== undefined && (
          <Typography variant={'subtitle2'} margin={1} align={'left'}>
            {description}
          </Typography>
        )}
        <Box margin={1}>
          {moduleConfiguration.enabled ? (
            <Typography
              display={'flex'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <CheckCircleOutlineTwoToneIcon color={'success'} />
              Enabled
            </Typography>
          ) : (
            <Typography>
              <ErrorOutlineTwoToneIcon color={'error'} />
              Not enabled
            </Typography>
          )}
        </Box>

        {moduleConfiguration.actionsNeeded?.map(action => {
          if (action === 'tenant-authorization') {
            return (
              <Grid
                container
                spacing={2}
                display={'flex'}
                alignItems={'center'}
              >
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant={'subtitle2'} align={'left'}>
                    Connection needs authorization by an end-user. Click button
                    to begin the process.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant={'contained'}
                    component="label"
                    size={'small'}
                    onClick={onAuthorize}
                    color={'warning'}
                  >
                    Authorize
                  </Button>
                </Grid>
              </Grid>
            );
          }
        })}
      </Box>
    </Grid>
  );
};

const TenantConnectionsView = (): JSX.Element => {
  const integrationService = useIntegrationService();
  const [, setIsLoading] = useState<boolean>(false);
  const [connections, setConnections] = useState<ModuleConfiguration[]>([]);

  useEffect(() => {
    (async () => {
      await executeApiCall({
        callFunction: () => integrationService.listModuleConfigurations(),
        setResult: setConnections,
        setPending: setIsLoading,
      });
    })();
  }, [integrationService, setConnections, setIsLoading]);

  return (
    <Grid container>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {connections.map(moduleConfiguration => {
          return <Connection moduleConfiguration={moduleConfiguration} />;
        })}
      </Grid>
    </Grid>
  );
};

export default TenantConnectionsView;
