import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

import AccessTokenProvider from '@apus/common-ui/providers/AccessTokenProvider';
import TenantContextProvider from '@apus/common-ui/providers/TenantContextProvider';
import WebsocketProvider from './components/provider/WebsocketProvider';
import AppContextProvider from './components/provider/AppContextProvider';
import amplifyConfiguration from './amplify-configuration';
import { JSX, useEffect } from 'react';
import * as React from 'react';
import { Login } from './components/auth/Login';
import ServiceProviderAppView from './views/ServiceProviderAppView';
import { RequireAuth } from './components/auth/RequireAuth';
import OAuthView from './views/OAuthView';
import Dashboard from './views/Dashboard';
import UserContextProvider from '@apus/common-ui/providers/UserContextProvider';
import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';
import { serviceProviderRoutes } from './routes/service-provider-routes';
import { tenantRoutes } from './routes/tenant-routes';
import useUser from '@apus/common-ui/hooks/useUser';
import Landing from './views/Landing';
import TenantAppView from './views/TenantAppView';
import { UserRole } from '@apus/common-lib/api/interface/integration-service';

function appRoutes(user?: SignedInUser) {
  if (user === undefined) return [];
  if (user.roles.includes(UserRole.PlatformAdmin)) return serviceProviderRoutes;
  return tenantRoutes;
}

function AppDashBoard({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}): JSX.Element {
  const user = useUser();
  const routes = appRoutes(user);

  return <Dashboard routes={routes}>{children}</Dashboard>;
}

function AppView({}: {}): JSX.Element {
  const user = useUser();

  if (user === undefined) return <Landing />;
  if (user.roles.includes(UserRole.PlatformAdmin))
    return <ServiceProviderAppView />;
  return <TenantAppView />;
}

function App() {
  Amplify.configure(amplifyConfiguration);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Authenticator.Provider>
      <UserContextProvider>
        <AccessTokenProvider>
          <TenantContextProvider>
            <AppContextProvider>
              <WebsocketProvider>
                <BrowserRouter>
                  <AppDashBoard>
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/oauth/*"
                        element={
                          <RequireAuth>
                            <OAuthView />
                          </RequireAuth>
                        }
                      />
                      <Route path="/*" element={<AppView />}></Route>
                    </Routes>
                  </AppDashBoard>
                </BrowserRouter>
              </WebsocketProvider>
            </AppContextProvider>
          </TenantContextProvider>
        </AccessTokenProvider>
      </UserContextProvider>
    </Authenticator.Provider>
  );
}

export default App;
